/* eslint-disable no-console */

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(registration => {
        // ensure there is not a previous SW waiting
        if (registration.waiting) {
          registration.waiting.postMessage('SKIP_WAITING');
        }

        // detect SW update available
        registration.addEventListener('updatefound', () => {
          if (registration.installing) {
            // wait for SW to be installed
            registration.installing.addEventListener('statechange', () => {
              if (registration.waiting) {
                if (navigator.serviceWorker.controller) {
                  // if there's an existing controller, means there is a previous SW
                  registration.waiting.postMessage('SKIP_WAITING');
                } else {
                  // otherwise it's the first install, nothing to do
                }
              }
            });
          }
        });
      })
      .catch(error => console.error(`Error during SW registration: ${error}`));

    // detect controller change (new SW activated) and refresh the page
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload();
    });
  });
}
